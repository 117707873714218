import styled from '@emotion/styled'

const Figure = styled.figure`
  position: relative;
  display: block;

  height: 100%;

  img {
    height: 100%;
  }
`

const Overlay = styled.div`
  position: absolute;
  bottom: 0;
  top: 0;

  width: 100%;

  background: linear-gradient(
    180deg,
    rgba(238, 238, 238, 0) 0%,
    rgba(66, 66, 66, 1) 100%
  );
  mix-blend-mode: multiply;
`

const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;

  position: absolute;
  top: 0;
  bottom: 0;

  width: 100%;
  padding: 1.222222222222222rem 1.777777777777778rem;

  color: #fff;
`

const Title = styled.h3`
  max-width: 100%;
  margin-bottom: 0.5rem;

  letter-spacing: 1.09px;
  text-overflow: ellipsis;
  font-size: 1.333333333333333rem;
  font-weight: 400;

  overflow: hidden;
  white-space: nowrap;
`

export { Figure, Overlay, Info, Title }
