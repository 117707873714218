// Library
import Container from 'library/Container'

// Components
import Card from './Card'

// Styles
import { Wrapper, Content } from './style'

const OtherProjects = ({ cards }) => (
  <Wrapper>
    <Container>
      <h2>Outros Projetos</h2>
      <Content>
        {cards &&
          cards.map(({ title, slug, data: { card_image } }, index) => (
            <Card key={index} title={title} slug={slug} image={card_image} />
          ))}
      </Content>
    </Container>
  </Wrapper>
)

export default OtherProjects
