import styled from '@emotion/styled'

const Wrapper = styled.section`
  margin-bottom: 2rem;

  h2 {
    margin: 2rem 0;

    font-size: 2rem;
    color: ${({ theme }) => theme.colors.primary};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.phone}px) {
    margin-bottom: 5.5rem;
  }
`

const Content = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: 9rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.phone}px) {
    gap: 1rem;
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    gap: 2rem;
  }
`

export { Wrapper, Content }
