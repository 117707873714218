import Link from 'next/link'

// Library
import LinkArrow from 'library/Icons/LinkArrow'

// Components
import ResponsiveImage from 'components/ResponsiveImage'

// Styles
import theme from 'styles/theme'
import { Figure, Overlay, Info, Title } from './style'

const Card = ({ title, slug, image }) => (
  <div>
    <Link
      href={`/projetos/[project]?slug=${slug}`}
      as={`/projetos/${slug}`}
      passHref
    >
      <a title={title}>
        <Figure>
          <ResponsiveImage
            image={image}
            alt={title}
            sizes={`
              (max-width: ${theme.breakpoints.phone - 1}px) 100vw,
              (max-width: 1248px) calc(100vw / 3),
              416px
            `}
          />
          <Overlay />
          <Info>
            <Title>{title}</Title>
            <LinkArrow dark />
          </Info>
        </Figure>
      </a>
    </Link>
  </div>
)

export default Card
