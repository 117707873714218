import styled from '@emotion/styled'

import MainContainer from 'library/Container'
import { Wrapper } from 'components/OtherProjects/style'

const Content = styled(MainContainer)`
  margin-bottom: 4rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.phone}px) {
    margin-bottom: 3.555555555555556rem;
  }
`

const Text = styled.p`
  text-align: center;
`

export { Content, Text }
