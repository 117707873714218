import { useEffect, useState } from 'react'

// Api
import Starlight from 'Starlight'

// Components
import Layout from 'components/Layout'
import SectionHeader from 'components/SectionHeader'
import OtherProjects from 'components/OtherProjects'
import SeeMore from 'components/SeeMore'

// Styles
import { Content, Text } from 'styles/pages/404'


const Custom404 = ({ footer, header }) => {
  const [projects, setProjects] = useState(null)

  useEffect(() => {
    Starlight.entries.list('projetos', { order: 'published_at:asc', limit: 3 })
      .then(response => {
        setProjects(response)
      })
      .catch(response => {
        console.log(response)
      })
  }, [])

  return (
    <Layout header={header} footer={footer} darkHeader>
        <Content as='section'>
          <SectionHeader isFirstElement title='Erro 404'/>
          <Text>A página que você procurou não existe.</Text>
        </Content>
        {projects?.data && (
          <OtherProjects cards={projects.data}/>
        )}
        <SeeMore link='/'>Página Inicial</SeeMore>
    </Layout>
  )
}

export default Custom404
